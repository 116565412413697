<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-form class="d-flex" @submit.prevent="fetch(1)">
              <b-form-input class="mr-1" v-model="filter.q"
                            placeholder="Recherche par Nom role"/>
              <b-button type="submit" variant="primary">Recherche</b-button>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card

        no-body
    >

      <div class="m-2">
        <b-button
            variant="primary"
            to="/roles/creer"
        >
          Créer role
        </b-button>
      </div>
      <b-table v-if="items"
               ref="selectableTable"
               :fields="fields"
               :items="items"

               responsive
               class="mb-0"

      >
        <template #cell(actions)="data">
         
   
          <b-button  variant="gradient" class="btn-icon" :to="{ name: 'editer-role', params : {id : data.item.id }}">
            <feather-icon icon="EyeIcon"/>
          </b-button>
        </template>
      </b-table>


    </b-card>

    <div v-if="pagination.total" class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>

import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from "@/libs/axios";


export default {
  name : "RoleListPage",
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pagination: {},
      fields: ['id', {key: 'libelle', label: 'Site'}, {key: 'actions'}],      /* eslint-disable global-require */
      items: [],
      filter: {
        q: ''
      },
      is_loading : false,

    }
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      
      let _this = this;
      _this.is_loading = true;
      axios.get('/api/roles', {
        params: {
          page: page,
          q: _this.filter.q
        }
      }).then(function (responses){
 
        _this.items = responses.data.data;
        _this.pagination = responses.data.meta;
        _this.is_loading = false;
      })
   
    }
  },
}
</script>
 
